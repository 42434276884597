import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat, sans-serif",
    fontWeightBold: 700,
  },
  // 他のテーマ設定...
});

export default theme;
